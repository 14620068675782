import Axios from 'axios';

//Axios instance for all protected APIs
const axiosInstance = Axios.create({
  baseURL: 'https://easy.dealsclassified.online/backend/public/api',
  // baseURL: 'http://localhost/bookmark/public/api',
  headers: {
    'Content-Type': 'application/json',
  }, 
});

//Axios Instance for public APIs
export const axiosInstanceWithoutAuth = Axios.create({
  baseURL: 'https://easy.dealsclassified.online/backend/public/api',
  // baseURL: 'http://localhost/bookmark/public/api',
});

export default axiosInstance;
